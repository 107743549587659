@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.snackbar-hide-exit {
  animation: FadeOut;
  animation-duration: 1s;
}
